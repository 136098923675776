// Search Form
// -------------------------
$search-form-container-background-color: #fff;
$search-form-container-background-opacity: 1;
$input-field-icon-color: $_neutral_gray-05;

// Hero Button Group (on Search Form)
// -------------------------
//$hero-button-group-width: 100%;
$hero-button-group-btn-offset: 0;
$hero-button-group-btn-icon-valign: bottom;
$hero-button-group-btn-display: inline-block;
$hero-button-group-height: initial;


// Forms
// -------------------------
$input-bg: $_neutral_gray-01;
$input-bg-color-alt: $_neutral_white;
$input-border: $_neutral_gray-03;
$input-box-shadow: none;
$input-height-base: 40px;
$input-color-placeholder: $_neutral_gray-04;
$input-color: $_neutral_gray-06;

$form-label-font-weight: normal;
$form-validation-text-font-weight: normal;

$tt-dropdown-text-color: $_primary_bright-blue;
$tt-dropdown-hover-text-color: $tt-dropdown-text-color;
$tt-dropdown-hover-bg-color: $_neutral_gray-01;

$checkout-terms-conditions-background-color:  $_neutral_gray-01;