// Popovers
// -------------------------
$popover-bg: #fff;
$popover-body-bg-alt: $_neutral_gray-01;
$popover-border-color: transparent;
$popover-border-radius: 3px;
$popover-box-shadow: $_box-shadow-full;

$popover-title-bg: #fff;
$popover-title-text-color: $text-color;
$popover-title-font-weight: 600;

$popover-arrow-width: 10px;
$popover-arrow-color: #fff;

$popover-arrow-outer-width: ($popover-arrow-width + 1);
$popover-arrow-outer-color: transparent;