// DLS Icons
// -------------------------
//$icon-font-size: 100%;

$icon-alert__help: "\eb66";
$icon-alert__help--outline: "\eb66";
$icon-alert__info: "\eb68";
$icon-alert__info--outline: "\eb68";
$icon-alert__warning: "\eb70";
$icon-alert__warning--outline: "\eb70";

$icon-product__bundle: "\eb5c";
$icon-product__car: "\eb06";
$icon-product__flight: "\eb02";
$icon-flight-stopover: "\eb24";
$icon-product__hotel: "\eb24";
$icon-product__activity: "\eb16";
$icon-flight__duration: "\eaef";
$icon-flight__featured: "\eb02";

$icon-product__add: "\ead1";
$icon-product__add--outline: "\ead0";
$icon-product__remove: "\eacb";
$icon-product__remove--outline: "\eaca";
$icon-product__deal: "\ea73";
$icon-product__lowest-price: "\ea74";
$icon-product__selected: "\eb6e";
$icon-product__keep: "\eb6e";

$icon-shopping-cart: "\eb0a";

$icon-input__search: "\ea3b";
$icon-modify-search: "\ea3b";
$icon-search-form__location--origin: "\ea3b";
$icon-search-form__location--destination: "\ea3b";
$icon-search-form__date: "\eadf";

$icon-payment-options: "\ea5b";
$icon-user: "\eadb";
$icon-lock: "\ea2a";
$icon-undo: "\eb6c";
$icon-refresh-replay: "\eb6c";
$icon-collapsible--closed: "\eafe";
$icon-collapsible--opened: "\eaf6";
$icon-nav__dropdown: "\eaf6";

.icon-undo,
.icon-product__keep,
.icon-product__selected,
.icon-product__deal,
.icon-product__lowest-price,
.icon-input__search,
.icon-modify-search,
.icon-product__bundle,
.icon-product__car,
.icon-product__flight,
.icon-product__hotel,
.icon-product__activity,
.icon-search-form__location--origin,
.icon-search-form__location--destination,
.icon-search-form__date,
.icon-payment-options,
.icon-flight__duration,
.icon-flight__featured,
.icon-user,
.icon-product__add,
.icon-product__add--outline,
.icon-product__remove,
.icon-product__remove--outline,
.icon-flight-stopover,
.icon-lock,
.icon-alert__help,
.icon-alert__help--outline,
.icon-alert__info,
.icon-alert__info--outline,
.icon-alert__warning,
.icon-alert__warning--outline,
.icon-refresh-replay,
.icon-collapsible--closed,
.icon-collapsible--opened,
.icon-nav__dropdown,
.icon-shopping-cart {
  font-family: "dls-icons" !important;
  vertical-align: middle !important;
}

.icon-collapsible--closed,
.icon-collapsible--opened,
.icon-nav__dropdown {
  font-size: 14px !important;
}

$icon-branded-fares__included: "\eb62";
$icon-branded-fares__available-for-charge: "\ea97";
$icon-branded-fares__not-available: "\eac3";

.list-icon-bullets > li {
  &.icon-branded-fares__included:before,
  &.icon-branded-fares__available-for-charge:before,
  &.icon-branded-fares__not-available:before {
    font-family: "dls-icons" !important;
  }
}

// Number Stepper Icons
$number-stepper-icon-size: 150%;
$number-stepper-icon-color-disabled: $_neutral_gray-03;

// Special Amenities (Hotel)
@mixin special-amenities-icon-style($iconSize, $iconColor) {
  display: inline-flex;
  margin-bottom: 12px;
  width: 100%;
  margin-left: 0;

  &:before {
    font-family: SpecialAmenitiesIcons;
    margin-right: 15px;
    font-size: $iconSize;
    width: $iconSize;
    height: $iconSize;
    color: $iconColor;
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 0 0 $iconSize;
  }
}

.special-program-thc~.product-body .special-amenities {
  ul {
    padding-left: 0;
  }

  li {
    @include special-amenities-icon-style(25px, $_primary_bright-blue);

    &.icon-spl-property:before {
      content: "\e90c";
    }

    &.icon-std-upgrade:before {
      content: "\e90e";
    }

    &.icon-std-earlycheck-in:before {
      content: "\e901";
    }

    &.icon-std-latecheck-out:before {
      content: "\e905";
    }

    &.icon-add-complimentaryparking:before {
      content: "\e90a";
    }

    &.icon-add-complimentaryminibar:before {
      content: "\e908";
    }

    &.icon-add-complimentarybreakfast:before {
      content: "\e900";
    }
  }
}

.case-handheld .special-program-fhr + .product-body .icon-payment-options {
  color: #fff;
}

.special-program-fhr~.product-body .special-amenities {
  ul {
    padding-left: 0;
  }

  li {
    @include special-amenities-icon-style(25px, $_neutral_white);

    &.icon-spl-property:before {
      content: "\e90c";
    }

    &.icon-spl-spaservices:before {
      content: "\e90c";
    }

    &.icon-spl-foodandbeverage:before {
      content: "\e90c";
    }

    &.icon-fhr-upgrade:before {
      content: "\e90e";
    }

    &.icon-fhr-earlycheck-in:before {
      content: "\e901";
    }

    &.icon-fhr-breakfast:before {
      content: "\e900";
    }

    &.icon-fhr-wifi:before {
      content: "\e90d";
    }

    &.icon-fhr-latecheck-out:before {
      content: "\e905";
    }

    &.icon-add-complimentaryparking:before {
      content: "\e90a";
    }

    &.icon-add-complimentaryminibar:before {
      content: "\e908";
    }

    &.icon-add-complimentaryclublounge:before {
      content: "\e90b";
    }

    &.icon-add-complimentarytransportation:before {
      content: "\e909";
    }
  }
}

// Icon Lock
@mixin icon-locked-style($size, $display, $text-align) {
  font-size: $size;
  display: $display;
  text-align: $text-align;
}

.icon-lock {
  &:before {
    content: $icon-lock;
  }
}

.product-locked span,
.special-program-locked {
    @extend .icon-lock;
}

.case-base {
  .product-locked {
    span {
      margin: 20px auto;
      @include icon-locked-style(84px, block, center);
    }
  }
}

.case-handheld {
  .product-locked {
    display: flex;
    flex-direction: row-reverse;

    span {
      display: inline-block;
      margin-right: 10px;
      font-size: 43px;
    }
  }
}

.cobrand-catravel,
.cobrand-cafrtravel {
  .thc-benefit-icons {
    &:nth-child(1):before {
      content: "\e90c";
    }
  }
}
