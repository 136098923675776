// Buttons
// -------------------------
$btn-font-family: $_f-base;
$btn-letter-spacing: 0;

$btn-font-size: $font-size-base;
$btn-font-size_handheld: $font-size-base_handheld;

$btn-font-weight: 400;
$btn-border-radius: 4px;
$border-radius-large: 4px;

$btn-padding-vertical: 8px;
$btn-padding-horizontal: 8px;
$btn-lg-padding-vertical: 14px;
$btn-xl-padding-horizontal: 30px;
$btn-xl-font-size: 16px;

// Primary
$btn-primary-color: $_neutral_white;
$btn-primary-bg: $brand-primary;
$btn-primary-border: $_primary_bright-blue;
$btn-primary-bg-color-hover: darken($btn-primary-bg, 5%);

$btn-primary-inverse-color: $_neutral_white;
$btn-primary-inverse-bg: $_legacy_light-aqua;

$btn-primary-active-color: $_neutral_white;
$btn-primary-active-bg-color: darken($btn-primary-color, 10%);

$btn-primary-inactive-bg-color: $_legacy_light-aqua;

// Secondary
$btn-secondary-color: $_primary_bright-blue;
$btn-secondary-bg: $_neutral_white;
$btn-secondary-border: $_primary_bright-blue;

$btn-secondary-hover-bg-color: $_neutral_gray-02;
$btn-secondary-border-hover: $_primary_bright-blue;

$btn-secondary-active-color: $_primary_bright-blue;
$btn-secondary-active-bg-color: #d4d4d4;
$btn-secondary-active-border-color: $btn-secondary-active-color;

$btn-secondary-inactive-bg-color: $_neutral_gray-04;
$btn-secondary-inactive-border-color: $_neutral_gray-04;

// Default
$btn-default-color: $_primary_bright-blue;
$btn-default-bg: $_neutral_white;
$btn-default-border: $_primary_bright-blue;

$btn-default-bg-color-hover: $_neutral_gray-02;
$btn-default-border-hover: $_primary_bright-blue;
$btn-default-color-hover: $_primary_bright-blue;

$btn-default-color-active: $_primary_deep-blue;
$btn-default-bg-color-active: $_neutral_gray-02;
$btn-default-border-active: $_primary_deep-blue;

// Search Form Pill
$btn-search-pill-color-active: $_primary_deep-blue;
$btn-search-pill-bg-color-active: $_neutral_gray-02;
$btn-search-pill-border-active: $_primary_deep-blue;

// Toggles
$btn-toggle-color-hover: $btn-primary-bg;
$btn-toggle-bg-color-hover: $_neutral_gray-02;
$btn-toggle-border-hover: $btn-primary-bg;

$btn-toggle-color-active: $btn-primary-color;
$btn-toggle-bg-color-active: $btn-primary-bg;
$btn-toggle-border-active: $btn-primary-bg;

// Disabled (all variants)
$btn-disabled-color: $_neutral_gray-04;
$btn-disabled-bg-color: $_neutral_gray-01;

$aside-contents-container-close-btn-color: $_neutral_white;

// Style Overrides
// -------------------------
.btn {
  transition: all .2s ease-in-out;
  transition-property: color, background-color, border-color;
}