// Alerts
// -------------------------

$alert-padding: 20px;
$alert-link-font-weight: normal;

$alert-default-bg: #fff;
$alert-default-text: $_neutral_gray-05;
$alert-default-border: $_status_neutral;

$alert-success-bg: #fff;
$alert-success-text: $_status_success;
$alert-success-border: $_status_success;

$alert-warning-bg: #fff;
$alert-warning-text: $_status_warning;
$alert-warning-border: $_status_warning;

$alert-danger-bg: #fff;
$alert-danger-text: $_status_warning;
$alert-danger-border: $_status_warning;

$alert-info-bg: #fff;
$alert-info-text: $_neutral_gray-05;
$alert-info-border: $_status_neutral;