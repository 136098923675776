// Fonts
// -------------------------
$_f-base: "Helvetica Neue", Helvetica, Arial, sans-serif;
$_f-bs-reg: BentonSans, "Helvetica Neue", Helvetica, Arial, sans-serif;

// TODO: Decide if above font is needed, commented from _webfonts to prevent downloading asset
//$_f-bs-book: BentonSans-Book, "Helvetica Neue", Helvetica, Arial, sans-serif;

/* TESTING */
//.f-base {font-family: $_f-base};
//.f-bs-reg {font-family: $_f-bs-reg};
//.f-bs-book {font-family: $_f-bs-book};

$font-family-base: $_f-base;

$text-color: $_neutral_gray-06;
$font-size-base: 15px;
$font-size-base_handheld: 15px;

$product-price-font-size: 24px;

$line-height-base: 1.5;
$line-height-xs: 1.2;

$text-medium-font-weight: normal;

$strikethrough-text-color: $brand-danger;
$points-text-color: $text-color;
$price-message-promotion-text-color: $text-color;
$hero-message-text-color-transparent_handheld: $text-color;

// Interactive
// -------------------------
$link-color: $_primary_bright-blue;
$link-hover-color: $_primary_bright-blue;
$map-marker-link-color: #fff;
$strikethrough-text-color: $text-color;
$strikethrough-text-decoration-color: $brand-danger;

// Headings
// -------------------------
$headings-font-family: $_f-bs-reg;

$font-size-h1: 32px;
$font-size-h2: 24px;
$font-size-h3: 20px;
$font-size-h4: $font-size-base;

$font-size-h1_handheld: 30px;
$font-size-h2_handheld: 24px;
$font-size-h3_handheld: 20px;
$font-size-h4_handheld: 18px;

$hero-message-header-font-size: 38px;
$hero-message-header-font-size_handheld: 30px;

$headings-line-height: 1.2;
$headings-font-weight: 300;
$headings-font-weight-h3: 400;
$headings-font-weight-h4: 400;

$media-heading-font-weight: 400;

// Kards
$kard-title-font-size: $font-size-h3;
$kard-title-font-weight: $headings-font-weight;
$kard-title-text-color: $_primary_bright-blue;
$kard-alt-title-text-color: #fff;

// Search Form
$btn-search-pill-font-size: $font-size-base;

// Checkout
$checkout-travelers-details-legend-background-color: $_neutral_gray-01;
$checkout-travelers-details-legend-text-transform: uppercase;
$checkout-travelers-details-legend-font-weight: 500;
$checkout-terms-conditions-text-color: $_neutral_gray-05;
$checkout-display-terms-font-size: 13px;
$checkout-terms-conditions-font-size: 13px;
$checkout-display-terms-line-height: $line-height-xs;