// Modals
// -------------------------
$modal-content-border-color: transparent;
$modal-content-fallback-border-color: #fff;

$modal-backdrop-bg: #000;
$modal-header-bg: $_primary_bright-blue;
$modal-header-text-color: #fff;
$modal-header-border-color: transparent;
$modal-header-close-opacity: 1;
$modal-footer-border-color: $_neutral_gray-02;