$star-rating-active-text-color: $_status-attention;
$product-additional-options-drawer-bg-color: $_neutral_gray-02;
$main-contents-section-body-background-color: #fff;
$section-header-background-color_handheld: #fff; // cart summary page header HH
$product-additional-options-title-text-align: left;
$product-price-container-text-alignment_handheld: left;

$product-benefits-info-link-alignment_handheld: left;
$product-benefits-info-link-alignment: right;
$product-benefits-info-link-decoration: underline;
$product-deal-special-program-link-decoration: underline;
$product-deal-special-program-thc-link-decoration: none;
$product-deal-special-program-car-background-color: #fff;
$product-deal-special-program-car-body-text-color: $text-color;

$product-featured-font-weight: 400;

$price-font-family: $_f-bs-reg;

$product-border-color-selected: $brand-success;
$product-selected-price-container-bg-color: $_neutral_gray-01;

// Kards
// -------------------------
$kard-alt-bg-color: $_primary_bright-blue;
$marketing-promotion-groups-bg-color: $_neutral_gray-01;
$shadow-md: 0 0 0 0 rgba(0, 0, 0, 0.0), 0 0 0 0 rgba(0, 0, 0, 0.0);

// Deals
// -------------------------

$product-deal-bg-color: $_primary_deep-blue;
$rate-deal-bg-color: $_primary-deep-blue;

$product-deal-client-approved-bg-color_handheld: $gray-lighter;
$product-deal-client-exclusive-bg-color: $_primary_deep-blue;

$product-deal-special-program-bg-color: $_neutral-black;
$product-deal-special-program-bg-color_handheld: $gray-lighter;

$product-body-border-color-xsell: $_neutral-gray-02;
$product-body-bg-color-xsell: #fff;

$product-deal-special-program-body-bg-color: $_neutral_gray-05;
$product-deal-special-program-body-text-color: #fff;
$product-deal-special-program-star-rating-color: $_neutral_white;
$product-deal-special-program-price-container-background-color: $_neutral_gray-02;
$product-deal-special-program-body-border: none;
$product-deal-special-program-banner-height: 50px;
$product-deal-special-program-banner-height_handheld: 50px;
$product-deal-special-program-thc-body-text-color: $text-color;
$product-deal-special-program-thc-body-bg-color: $_neutral_gray-01;
$product-deal-special-program-thc-body-accent-color: $_primary_bright-blue;
$product-deal-special-program-thc-star-rating-color: $_primary_bright-blue;
$product-deal-special-program-thc-price-container-background-color: $_neutral_white;
$product-deal-special-program-border-radius: 0;
$product-benefits-background-color: $_neutral_gray-05;

$rate-deal-content: none;
$rate-deal-margins: 0 0 10px 0;
$rate-deal-border-radius: 0;

$_product-deal-special-program-background-size: 300px;
$_product-deal-special-program-hotel-detail-background-size: 350px;
$_product-deal-special-program-aside-background-size: 90%;

$_product-deal-special-program-fhr-background-image: $_neutral_black url('#{$assetsPath}/fhr_wordmark.svg') no-repeat 20px center;
$_product-deal-special-program-thc-background-image: url('#{$assetsPath}/thc_wordmark.svg') no-repeat 20px center;

$_product-deal-special-program-hotel-detail-thc-background-image: url('#{$assetsPath}/thc_details_wordmark.svg') left center no-repeat;
$_product-deal-special-program-hotel-detail-fhr-background-image: url('#{$assetsPath}/fhr_details_wordmark.svg') left center no-repeat;
$_product-deal-special-program-thc-background-color: $_primary_deep-blue;
$_product-deal-special-program-mapview-background-size: 85%;
$_product-deal-special-program-thc-mapview-background-size: 85%;

$product-featured-background-color: $_neutral_gray-03;
$product-featured-name-text-color: $_primary_deep-blue;
$product-featured-deal-border: 3px solid $_neutral_gray-03;
$product-featured-font-weight: 400;

// Dashcart
// -------------------------
$dashcart-product-unit-background-color: transparent;

// Flight Matrix
// -------------------------

$flight-matrix-cell-text-color: $text-color;
$flight-matrix-headers-text: $text-color;
$flight-matrix-selected-dates-background-color: $_primary_deep-blue;
$flight-matrix-lowest-price-selected-cell-background-color: $_primary_bright-blue-20;
$flight-matrix-lowest-price-color: $_primary_bright-blue-20;
$flight-matrix-lowest-price-text-color: $text-color;
$flight-matrix-lowest-price-icon-color: $text-color;
$flight-matrix-selected-cell-background-color: #fff;
$flight-matrix-selected-cell-outline-color: $_primary_deep-blue;

$flight-matrix-legend-label-text-color: $text-color;
$flight-matrix-legend-lowest-price-icon-text-color: $text-color;

$product-promo-border: 1px solid $_neutral_gray-02;

// Special Hotels
// -------------------------

.fhr-benefit-icons {
  @extend .thc-benefit-icons;

  &.icon-spl-property:before {
    content: "\e90c";
  }

  &.icon-spl-spaservices:before {
    content: "\e90c";
  }

  &.icon-spl-foodandbeverage:before {
    content: "\e90c";
  }

  &.icon-fhr-upgrade:before {
    content: "\e90e";
  }

  &.icon-fhr-earlycheck-in:before {
    content: "\e901";
  }

  &.icon-fhr-breakfast:before {
    content: "\e900";
  }

  &.icon-fhr-wifi:before {
    content: "\e90d";
  }

  &.icon-fhr-latecheck-out:before {
    content: "\e905";
  }

  &.icon-add-complimentaryparking:before {
    content: "\e90a";
  }

  &.icon-add-complimentaryminibar:before {
    content: "\e908";
  }

  &.icon-add-complimentaryclublounge:before {
    content: "\e90b";
  }

  &.icon-add-complimentarytransportation:before {
    content: "\e909";
  }
}

.thc-benefit-icons {
  &.icon-spl-property:before {
    content: "\e90c";
  }

  &.icon-std-upgrade:before {
    content: "\e90e";
  }

  &.icon-std-earlycheck-in:before {
    content: "\e901";
  }

  &.icon-std-latecheck-out:before {
    content: "\e905";
  }

  &.icon-add-complimentaryparking:before {
    content: "\e90a";
  }

  &.icon-add-complimentaryminibar:before {
    content: "\e908";
  }

  &.icon-add-complimentarybreakfast:before {
    content: "\e900";
  }
}

.icon-payment-options {
  color: $_primary_bright-blue;
}

//FHR & THC
.product-deal.special-program {
  //Need to add not([class*='chain-car-banner-']) because it doesn't apply for car results.
  &:not([class*='chain-car-banner-']) span {
    @extend .sr-only;
  }

  &:before {
    speak: none;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  #hotel-detail & {
    background-size: $_product-deal-special-program-hotel-detail-background-size;
  }

  &.special-program-fhr {
    #hotel-detail & { //desktop, aligns left
      background: $_product-deal-special-program-hotel-detail-fhr-background-image;
      background-size: $_product-deal-special-program-hotel-detail-background-size;
    }

    #similar-hotels-filmstrip & {
      background: $_product-deal-special-program-fhr-background-image;
      background-size: $_product-deal-special-program-aside-background-size;
    }

    //inside map view, single & cluster
    .popover-content &,
    .modal-content & {
      background-size: $_product-deal-special-program-mapview-background-size;
    }

    background: $_product-deal-special-program-fhr-background-image;
    background-size: $_product-deal-special-program-background-size;
  }

  //custom styles for THC specific products
  &.special-program-thc {
    //inside map view, single & cluster
    .popover-content &,
    .modal-content & {
      background-size: $_product-deal-special-program-thc-mapview-background-size;
    }
    #search-results-form-container &,
    #selected-hotel &,
    #your-itinerary &,
    #confirmation-page-container & {
      background-color: $_product-deal-special-program-thc-background-color;
    }

    #hotel-detail & {
      background: $_product-deal-special-program-hotel-detail-thc-background-image;
      background-size: $_product-deal-special-program-hotel-detail-background-size;
    }

    #similar-hotels-filmstrip & {
      background: $_product-deal-special-program-thc-background-color $_product-deal-special-program-thc-background-image;
      background-size: $_product-deal-special-program-aside-background-size;
    }
    background: $_product-deal-special-program-thc-background-image;
    background-size: $_product-deal-special-program-background-size;
  }
}

#aside-contents {
  .special-program {
    background-position: center;
    background-size:$_product-deal-special-program-aside-background-size;
  }
}

//FHR & THC section on hotel details
.product-benefits {
  &.hotel-benefit-fhr {
    li {
      @include special-amenities-icon-style(25px, $_neutral_white);
      @extend .fhr-benefit-icons;
    }
  }

  &.hotel-benefit-thc {
    background-color: $product-deal-special-program-thc-body-bg-color !important;
    color: $product-deal-special-program-thc-body-text-color !important;


    li {
      @include special-amenities-icon-style(25px, $_primary_bright-blue);
      @extend .thc-benefit-icons;
    }

    div {
      background-color: $product-deal-special-program-thc-body-bg-color;
      color: $product-deal-special-program-thc-body-text-color !important;
    }

    button {
      color: $product-deal-special-program-thc-body-accent-color !important;
    }
  }
}

.case-handheld {
  // Handheld homepage Welcome text
  .hero-message {
    display: none;
  }
  #hotel-detail {
    .product-deal.special-program {
      background-color: transparent;
      background-position: center center !important;
    }

    .product-benefits.hotel-benefit-thc ul {
      display: block;
    }
  }

  #similar-hotels-filmstrip {
    .special-program {
      &.special-program-fhr {
        background-color: #000;
      }
      &.special-program-thc {
        background-color: $_product-deal-special-program-thc-background-color;
      }
    }
  }

  #your-itinerary {
    .product-deal.special-program {
      background-size: $_product-deal-special-program-aside-background-size;
      background-position: 10px center;
    }
  }
}