// Datepicker Component
// -------------------------

$datepicker-border-color: transparent;
$datepicker-thead-border: none;
$datepicker-day-border-color: #e4e7e7;
$datepicker-box-shadow: $_box-shadow-full;
$datepicker-day-text-color: #484848;

$datepicker-thead-background-color: #fff;
$datepicker-thead-text-color: $datepicker-day-text-color;

$datepicker-day-current-background-color: #fff;
$datepicker-day-current-text-color: $datepicker-day-text-color;

$datepicker-day-selected-background-color: $_primary_bright-blue;
$datepicker-day-selected-text-color: #fff;
$datepicker-day-hover-background-color: $datepicker-day-selected-background-color;
$datepicker-day-hover-text-color: $datepicker-day-selected-text-color;

$datepicker-date-empty-background-color: $datepicker-day-border-color;
$datepicker-date-empty-text-color: transparent;

$datepicker-date-unselectable-background-color: #fff;
$datepicker-date-unselectable-text-color: #cacccd;
