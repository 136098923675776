// Pagination
// -------------------------

$pagination-bg: $_neutral-gray-01;
$pagination-color: $_primary_bright-blue;

$pagination-border: $_neutral-gray-01;
$pagination-outer-border-radius: 4px;

$pagination-hover-bg: $_neutral-gray-02;
$pagination-hover-color: $_primary_bright-blue;

$pagination-active-bg: $_primary_bright-blue;
$pagination-active-color: #fff;

$pagination-disabled-color: $_neutral_gray-04;