// Progress bar
// -------------------------
$progress-bar-width-desktop: 600px;
$progress-bar-height-desktop: 10px;
$progress-bar-height-handheld: 10px;
$progress-bar-background-color: $_neutral_gray-02;
$progress-bar-foreground-color: $_primary_bright-blue;
$progress-bar-foreground-bars-color: $_primary_bright-blue;

// Check mark loader - Used in Add to Cart, etc
// -------------------------
$checkloader-size: 70px;
$checkloader-check-thickness: 7px;
$checkloader-check-left: 11px;
$checkloader-check-height: 32px;
$checkloader-spin-time: 800ms;
