// Amex DLS Colors
// -------------------------
$_primary_bright-blue: #006FCF;
$_primary_bright-blue-60: #66A9E2;
$_primary_bright-blue-40: #99C5EC;
$_primary_bright-blue-20: #CCE2F5;

$_primary_deep-blue: #00175A;
$_primary_deep-blue-60: #66749C;
$_primary_deep-blue-40: #99A2BD;
$_primary_deep-blue-20: #CCD1DE;

$_neutral_black: #000;
$_neutral_white: #fff;
$_neutral_gray-01: #F7F8F9;
$_neutral_gray-02: #ECEDEE;
$_neutral_gray-03: #C8C9C7;
$_neutral_gray-04: #97999B;
$_neutral_gray-05: #53565A;
$_neutral_gray-06: #333;

$_status_neutral: #97999B;
$_status_info: $_primary_bright-blue;
$_status_success: #008767;
$_status_attention: #FDB92D;
$_status_moderate: #EA700B;
$_status_warning: #B42C01;

$_neutral: $_neutral_gray-04;

// Amex Legacy (non-DLS) Colors
// -------------------------
$_legacy_light-aqua: #00aed2;

// App Brand Color Mapping
// -------------------------
$gray-dark: $_neutral_gray-06;
$gray: $_neutral_gray-05;
$gray-medium: $_neutral_gray-04;
$gray-light: $_neutral_gray-03;
$gray-lighter: $_neutral_gray-02;
$almost-white: $_neutral_gray-01;

$brand-primary: $_primary_bright-blue;
$brand-success: $_status_success;
$brand-featured: $_primary_bright-blue;
$brand-warning: $_status_warning;
$brand-danger: $_status_warning;