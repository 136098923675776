$sub-header-bg-color: $_primary_bright-blue;
$site-header-logo-primary-min-height: 60px;
$site-header-background: #fff;
$site-footer-background-color: $_neutral_white;
$site-footer-padding-top: 0;
$header-brand-container-padding: 0;
$site-header-font-size: 13px;
$site-footer-font-size: $site-header-font-size;
$site-footer-line-height: 20px;

$loyalty-discount-dropdown-menu-has-caret: "";
$loyalty-discount-dropdown-menu-margin-top: 10px;

body {
  &.AXP_Responsive {
    &::before {
      content: '';
      height: 60px;
      display: block;
    }
  }
}

//header cc card
.case-base {
  .logged-in .header-container {
    @extend .flex-valign-middle;
    flex-direction: row-reverse;

    > div + div {
      margin-right: 10px;
    }
  }

  .header-container .header-login-description {
    margin-right: 10px;
  }

  .header-loyalty-container {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin: 10px 0;
  }
}

.logged-in.header-loyalty-container {
  font-size: $font-size-base;
}

//card art image
.header-container {
  .logo-image {
    width: 60px;
  }
}

//removes flex on important information
.page-search-form .show-on-homepage .kard {
  display: block;
  border: none;
}

.case-handheld {
  #site-header {
    .header-loyalty-container {
      .header-login-description {
        float: left;
        width: 60%;
        margin-left: 0;
      }

      .loyalty-summary {
        float: right;
        width: 56%;
      }
    }
  }
}

//Log Out btn
.header-container a.link {
  @extend .btn;
  @extend .btn-secondary;
}

//Log In button
.header-container {
  a.btn-default {
    @extend .btn-primary;
  }
}

// Hides inav login button
a#gnav_login {
  display: none !important;
}

#site-header {
  > .container > .clearfix {
    display: flex;
  }

  .header-login-description {
    text-align: left;
  }
}

#site-footer {
  .width-full {
    background-color: $_neutral_gray-01;
    margin-bottom: 20px;
  }

  .container {
    .logo-acta {
      padding-right: 10%;
    }

    .logo-tico {
      padding-left: 10%;
    }
  }

  .section-trip-advisor {
    &::after {
      content: '';
      display: block;
      height: 20px;
      margin-top: 11px;
    }
  }

  .custom-links {
    padding: 16px 8px 0 8px;

    ul {
      list-style-type: none;

      li {
        display: table-row-group;

        a {
          word-break: break-word;
        }

        &.dropdown-header {
          @extend .h3;
        }
      }
    }
  }
}

.page-search-form .show-on-homepage .kard {
  border: none;
  font-size: $site-footer-font-size;
  line-height: $site-footer-line-height;

  .h2,
  h2 {
    @extend .h3;
    font-weight: bold;
    margin: 0 !important;
  }
}

//Handheld hide logo
@media (max-width: $screen-md) {
  .case-handheld {
    .header-brand-container {
      display: none !important;
    }

    .header-loyalty-container {
      width: 100% !important;
      padding: $font-size-base_handheld $btn-font-size_handheld/2;
    }
  }
}

@media (min-width: $screen-xs) {
  .case-base .header-loyalty-container {
    width: 100% !important;
  }
}

// iNav fixes
//--------------------------
.axp-global-header__dls__module___2gPze .axp-global-header__dls__nav___3rzdg {
  z-index: 1020 !important;
}

.axp-global-header__GlobalHeader__headerSpacer___1QFWZ {
  height: 0 !important;
}

// new subNav
.sub-header {
  nav {
    position: relative !important;
  }
  .container {
    max-width: none;
    padding: 0;
  }
}

// Footer Important Information
//--------------------------
.show-on-homepage {
  display: none;
}

.page-search-form {
  .nts-terms-and-conditions {
    display: none;
  }

  .show-on-homepage {
    display: block;
  }
}

// hide the iNav header/footer and terms and conditions content on app loading and interstitial page
.app-loading,
.page-interstitial {
  #iNavNGI_Header,
  #iNavNGI_FooterMain,
  #iNav_DDA_Header,
  #iNav_DDA_Footer,
  #iNavNGI_FooterWrap,
  #iNav_header_wrap,
  #iNav_header,
  #iNav_footer,
  .nts-terms-and-conditions,
  .show-on-homepage,
  .axp-global-header__dls__module___2gPze,
  .axp-footer__dls__module___2gPze {
    display: none;
  }

  .status-bars-container {
    margin-top: 0;
  }
}

.nts-terms-and-conditions {
    font-size: $site-footer-font-size;
    line-height: $site-footer-line-height;
  h2 {
    font-size: $font-size-h3;
  }
}

// Custom Logos
// -------------------------
@mixin amex-header-logo($logoName) {
  #site-header .logo-primary {
    background: transparent url('#{$assetsPath}/#{$logoName}') 0 50%/contain no-repeat;
    background-size: 50% 70px;
  }
}

@mixin trip-advisor-background() {
  background: url('#{$assetsPath}/../../application/search-results/tripadvisor-logo-horizontal-lockup-registered-rgb.png') no-repeat;
  background-size: contain;
  width: 108px;
}

.cobrand-autravel,
.cobrand-auapi {
  @include amex-header-logo('DT_travel.svg');

  #site-footer {
    .section-trip-advisor {
      &::after {
        @include trip-advisor-background();
      }
    }
  }
}

.cobrand-catravel,
.cobrand-caapi {
  @include amex-header-logo('DT_travel_canada.svg');

  #site-header {
    .logo-primary {
      background-size: 100%;
    }
  }

  #site-footer {
    .section-trip-advisor {
      &::after {
        @include trip-advisor-background();
      }
    }
  }
}

.cobrand-cafrtravel,
.cobrand-cafrapi {
  @include amex-header-logo('DT_canada_voyages.svg');

  #site-header {
    .logo-primary {
      background-size: 100%;
    }
  }

  #site-footer {
    .section-trip-advisor {
      &::after {
        @include trip-advisor-background();
      }
    }
  }
}

.cobrand-detravel,
.cobrand-deapi {
  @include amex-header-logo('DT_reisen.svg');

  #site-footer {
    .section-trip-advisor {
      &::after {
        @include trip-advisor-background();
      }
    }
  }
}

.cobrand-estravel,
.cobrand-esapi {
  @include amex-header-logo('DT_viajes.svg');

  #site-footer {
    .section-trip-advisor {
      &::after {
        @include trip-advisor-background();
      }
    }
  }
}

.cobrand-frtravel,
.cobrand-frapi {
  @include amex-header-logo('DT_voyages.svg');

  #site-header {
    .logo-primary {
      background-size: 65%;
    }
  }

  #site-footer {
    .section-trip-advisor {
      &::after {
        @include trip-advisor-background();
      }
    }
  }
}

.cobrand-hkzhtravel,
.cobrand-hkzhapi {
  @include amex-header-logo('HK_travelheader_dt.svg');

  #site-footer {
    .section-trip-advisor {
      &::after {
        @include trip-advisor-background();
      }
    }
  }
}

.cobrand-intravel,
.cobrand-inapi {
  @include amex-header-logo('DT_travel.svg');

  #site-footer {
    .section-trip-advisor {
      &::after {
        @include trip-advisor-background();
      }
    }
  }
}

.cobrand-nztravel,
.cobrand-nzapi {
  @include amex-header-logo('DT_travel.svg');

  #site-footer {
    .section-trip-advisor {
      &::after {
        @include trip-advisor-background();
      }
    }
  }
}

.cobrand-sgtravel,
.cobrand-sgapi {
  @include amex-header-logo('DT_travel.svg');

  #site-footer {
    .section-trip-advisor {
      &::after {
        @include trip-advisor-background();
      }
    }
  }
}

.cobrand-hkapi {
  @include amex-header-logo('HK_travelheader_dt.svg');

  #site-footer {
    .section-trip-advisor {
      &::after {
        @include trip-advisor-background();
      }
    }
  }
}

.cobrand-itapi {
  @include amex-header-logo('IT_travelheader_dt.svg');

  #site-footer {
    .section-trip-advisor {
      &::after {
        @include trip-advisor-background();
      }
    }
  }
}

.cobrand-mxapi {
  @include amex-header-logo('MX_travelheader_dt.svg');

  #site-footer {
    .section-trip-advisor {
      &::after {
        @include trip-advisor-background();
      }
    }
  }
}

.cobrand-jpapi {
  @include amex-header-logo('JP_travelheader_dt.svg');

  #site-footer {
    .section-trip-advisor {
      &::after {
        @include trip-advisor-background();
      }
    }
  }
}

.cobrand-nlapi {
  @include amex-header-logo('NL_travelheader_dt.svg');

  #site-footer {
    .section-trip-advisor {
      &::after {
        @include trip-advisor-background();
      }
    }
  }
}

.cobrand-seapi {
  @include amex-header-logo('SE_travelheader_dt.svg');

  #site-footer {
    .section-trip-advisor {
      &::after {
        @include trip-advisor-background();
      }
    }
  }
}

.cobrand-uktravel,
.cobrand-ukapi,
.cobrand-ukagent {
  @include amex-header-logo('DT_travel.svg');

  #site-footer {
    .section-trip-advisor {
      &::after {
        @include trip-advisor-background();
      }
    }
  }
}

.cobrand-ittravel {
  @include amex-header-logo('DT_viaggi.svg');

  .page-content {
    .sec-code {
      @include secCodeVariant(amex);
      background: transparent url('#{$assetsPath}/security_code_amex.png') no-repeat;
    }
  }

  #site-footer {
    .section-trip-advisor {
      &::after {
        @include trip-advisor-background();
      }
    }
  }
}

.cobrand-mxtravel {
  @include amex-header-logo('DT_viajes.svg');

  .nts-terms-and-conditions,
  .show-on-homepage {
    &.container {
      clear: both;

      p {
        font-size: 10px;

        span {
          font-size: 2em;
        }
      }
    }
  }

  #site-footer {
    .section-trip-advisor {
      &::after {
        @include trip-advisor-background();
      }
    }
  }
}

.cobrand-jptravel {
  @include amex-header-logo('DT_travel.svg');

  #checkout-form-container {
    .hotelArrangements {
      input {
        width: 120%;
      }
    }
  }

  #site-footer {
    .section-trip-advisor {
      &::after {
        @include trip-advisor-background();
      }
    }
  }

  #responsiveWrapper_main #responsiveWrapper_sub {
    #iNavNGI_FooterMain {
      #iNavNGI_FooterWrap {
        #iNavNGI_Footer {
          width: 801px !important;

          #iNavFootSub {
            padding-right: 8px;
          }
        }
      }
    }
  }

  .case-handheld {
    .product .price-container .price-total {
      font-size: 14px;
    }
  }
}

.cobrand-nltravel {
  @include amex-header-logo('DT_travel.svg');

  #responsiveWrapper_main #responsiveWrapper_sub {
    #iNavNGI_Header {
      #iNavSANav {
        margin-top: 0;

        #iNavSANavCont {
          margin: 0 auto;
          border: 1px solid #ccc;
          width: 69.5em;
        }
      }
    }
  }

  #site-footer {
    .section-trip-advisor {
      &::after {
        @include trip-advisor-background();
      }
    }
  }
}

.cobrand-setravel {
  @include amex-header-logo('DT_travel.svg');

  #iNav_footer {
    .iNav_CopyRight {
      float: right;
      text-align: right;
      margin: 0;
      display: block;
      width: 295px;
    }
  }

  #site-footer {
    .section-trip-advisor {
      &::after {
        @include trip-advisor-background();
      }
    }
  }
}

.cobrand-hktravel {
  @include amex-header-logo('DT_travel.svg');

  .currency-symbol {
    margin-right: .2em;
  }

  #site-footer {
    .section-trip-advisor {
      &::after {
        @include trip-advisor-background();
      }
    }
  }
}